import ReactApexChart from 'react-apexcharts';

const gradientColors = {
  lowRisk: ['#CCED6F', '#72DF76'],
  moderateRisk: ['#EED661', '#CCED6F', '#72DF76'],
  highRisk: ['#EEAE66', '#EED661', '#CCED6F', '#72DF76'],
  veryHighRisk: ['#EE6360', '#EEAE66', '#EED661', '#CCED6F', '#72DF76'],
};

const getRiskLevelAndGradient = (value) => {
  if (value < 35) {
    return { riskLevel: 'Low Risk', gradientColors: gradientColors.lowRisk };
  }
  if (value < 75) {
    return { riskLevel: 'Moderate Risk', gradientColors: gradientColors.moderateRisk };
  }

  return { riskLevel: 'High Risk', gradientColors: gradientColors.veryHighRisk };
};

const RadialChart = ({ value }) => {
  const finalValue = value ? value : 0;
  const series = [finalValue];

  const { riskLevel, gradientColors } = getRiskLevelAndGradient(finalValue);

  const colorStops = gradientColors.map((color, index) => ({
    offset: (index / gradientColors.length) * 100,
    color: color,
    opacity: 1,
  }));

  const options = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#DFDFDF',
          strokeWidth: '100%',
          margin: 0,
          dropShadow: {
            enabled: false,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
        colorStops: colorStops,
      },
    },
    stroke: {
      lineCap: 'round',
    },
  };

  return (
    <div id="chart2" className="bar-chart pb-1 relative">
      <div className="absolute absolute bottom-6 left-1/2 transform -translate-x-1/2 text-center">
        <div className="text-black fs-24 lg:fs-18 xl:fs-24 font-bold leading-4"> {finalValue}% </div>
        <div className="fs-12 mb-1 lg:mb-0 xl:mb-1"> Rejection Rate </div>
        <div className="text-black fs-14"> {riskLevel}</div>
      </div>
      <ReactApexChart options={options} series={series} type="radialBar" />
    </div>
  );
};

export default RadialChart;
