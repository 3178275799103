import React, { Children, Component, FC, PropsWithChildren, ReactElement, memo } from 'react';
import { renderToString } from 'react-dom/server';
import {
  Circle,
  ClipPath,
  Defs,
  Ellipse,
  G,
  Line,
  LinearGradient,
  Path,
  Polygon,
  Polyline,
  RadialGradient,
  Rect,
  Stop,
  Svg,
  Text,
  Tspan,
} from '@react-pdf/renderer';

const isIterable = (obj: unknown): boolean => {
  if (obj === null || obj === undefined) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
};

const snakeToCamel = (str: string): string =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

const parseStyle = (input: string): Record<string, unknown> => {
  const output = {};
  const camelize = (str: string) => {
    return str.replace(/(?:^|[-])(\w)/g, (match, p1) => {
      p1 = match.substring(0, 1) === '-' ? p1.toUpperCase() : p1;
      return p1 ? p1 : '';
    });
  };
  const style = input.split(';');
  for (let i = 0; i < style.length; ++i) {
    const rule = style[i].trim();
    if (rule) {
      const ruleParts = rule.split(':');
      const key = camelize(ruleParts[0].trim());
      output[key] = ruleParts[1].trim();
    }
  }
  return output;
};

const validPresentationProps = [
  'color',
  'dominantBaseline',
  'fill',
  'fillOpacity',
  'fillRule',
  'opacity',
  'stroke',
  'strokeWidth',
  'strokeOpacity',
  'strokeLinecap',
  'strokeLinejoin',
  'strokeDasharray',
  'transform',
  'textAnchor',
  'visibility',
];

const validPropsLookup = {
  svg: ['width', 'height', 'viewBox', 'preserveAspectRatio'],
  line: ['x1', 'y1', 'x2', 'y2', ...validPresentationProps],
  polyline: ['points', ...validPresentationProps],
  polygon: ['points', ...validPresentationProps],
  path: ['d', ...validPresentationProps],
  rect: ['x', 'y', 'width', 'height', 'rx', 'ry', ...validPresentationProps],
  circle: ['cx', 'cy', 'r', ...validPresentationProps],
  ellipse: ['cx', 'cy', 'rx', 'ry', ...validPresentationProps],
  text: ['x', 'y', ...validPresentationProps],
  tspan: ['x', 'y', ...validPresentationProps],
  g: [...validPresentationProps],
  stop: ['offset', 'stopColor', 'stopOpacity'],
  linearGradient: ['x1', 'y1', 'x2', 'y2'],
  radialGradient: ['cx', 'cy', 'fx', 'fy', 'fr'],
};

export const parseSvg = (node: Node, scale: number = 1) => {
  if (scale <= 0) {
    throw new Error('Scale must be greater than 0');
  }

  if (node.hasChildNodes()) {
    return Array.from(node.childNodes).map<JSX.Element | null>((child: ChildNode, index: number) => {
      const { nodeName, attributes } = child as Element;
      const nodeNameLowerCase = nodeName.toLowerCase();

      if (!isIterable(attributes)) {
        return null;
      }

      let ReactPdfComponent: typeof Component<React.PropsWithChildren<any>>;

      switch (nodeNameLowerCase.replace(/[^a-z]/g, '')) {
        case 'svg':
          ReactPdfComponent = Svg;
          break;
        case 'line':
          ReactPdfComponent = Line;
          break;
        case 'polyline':
          ReactPdfComponent = Polyline;
          break;
        case 'polygon':
          ReactPdfComponent = Polygon;
          break;
        case 'path':
          ReactPdfComponent = Path;
          break;
        case 'rect':
          ReactPdfComponent = Rect;
          break;
        case 'circle':
          ReactPdfComponent = Circle;
          break;
        case 'ellipse':
          ReactPdfComponent = Ellipse;
          break;
        case 'text':
          ReactPdfComponent = Text;
          break;
        case 'tspan':
          ReactPdfComponent = Tspan;
          break;
        case 'g':
          ReactPdfComponent = G;
          break;
        case 'stop':
          ReactPdfComponent = Stop;
          break;
        case 'defs':
          ReactPdfComponent = Defs;
          break;
        case 'clippath':
          ReactPdfComponent = ClipPath;
          break;
        case 'lineargradient':
          ReactPdfComponent = LinearGradient;
          break;
        case 'radialgradient':
          ReactPdfComponent = RadialGradient;
          break;
        default:
          return null;
      }

      const props = {} as Record<string, unknown>;
      const validPropsForNode = validPropsLookup[nodeNameLowerCase];

      for (const attr of attributes) {
        const camelCaseAttrName = snakeToCamel(attr.name);
        if (validPropsForNode && validPropsForNode.includes(camelCaseAttrName)) {
          props[camelCaseAttrName] = attr.value;
        } else if (attr.name === 'style') {
          const style = parseStyle(attr.value);
          delete style.fontFamily;
          props.style = style;
        }
      }

      if (nodeNameLowerCase === 'svg' && scale !== 1) {
        const svgWidth = attributes.getNamedItem('width')?.value;
        const svgHeight = attributes.getNamedItem('height')?.value;
        props.width = Number(svgWidth || '0') * scale;
        props.height = Number(svgHeight || '0') * scale;
        props.viewBox = `0 0 ${svgWidth} ${svgHeight}`;
      }

      let children: (JSX.Element | null)[] | null = null;
      try {
        children = parseSvg(child, scale);
      } catch (e) {
        console.error('An error occurred processing node of type:', nodeName);
      }

      return (
        ReactPdfComponent && (
          <ReactPdfComponent key={`${child.nodeName}-${index}`} {...props}>
            {children}
          </ReactPdfComponent>
        )
      );
    });
  }
  return null;
};

export const getSvgDom = (svgString: string) => {
  return new DOMParser().parseFromString(svgString, 'image/svg+xml');
};

interface ReactPdfSvgProps {
  scale?: number;
}

const ReactPdfSvg: FC<PropsWithChildren<ReactPdfSvgProps>> = ({ scale = 1, children }) => {
  if (scale <= 0) {
    throw new Error('Scale must be greater than 0');
  }

  return Children.toArray(children).map((child) => {
    return parseSvg(getSvgDom(renderToString(child as ReactElement)), scale);
  });
};

export default memo(ReactPdfSvg);
