import React from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { ReactComponent as ExclamationSVG } from 'icons/custom/exclamation.svg';
import { ReactComponent as CloseSVG } from 'icons/custom/close.svg';
import { ReactComponent as FileSVG } from 'icons/custom/file.svg';

const InvalidCSVModal = ({ showModal, setShowModal, errors, handleRetry }) => {
  return (
    <Modal
      className="modal fade"
      show={showModal}
      onHide={() => setShowModal(false)}
      size="md"
      centered
      enforceFocus={false}
    >
      <div className="flex flex-column items-center	justify-center mt-4">
        <div className="flex w-full items-center justify-between px-4">
          <div className="text-black text-2xl font-medium">Upload CSV</div>
          <CloseSVG className="cursor-pointer" onClick={() => setShowModal(false)} />
        </div>
        <hr className="w-full mt-4" style={{ backgroundColor: 'gray' }} />
        <div
          className="w-12	h-12 rounded-full flex items-center	justify-center mb-3 mt-3"
          style={{ background: '#F5F8FE' }}
        >
          <ExclamationSVG />
        </div>
        <div className="text-black text-base font-medium mb-2">Some fields in your CSV file contain errors:</div>

        <div className="w-100 p-3 pb-2 pt-2">
          {errors.map((error, index) => (
            <div
              className="flex items-center justify-start text-black text-xs pl-2.5 pr-2.5 pt-3 pb-3 mb-2.5"
              style={{
                background: '#F5F8FE',
                border: '1px solid #3571E5',
                borderRadius: '4px',
              }}
              key={index}
            >
              <FileSVG style={{ marginRight: '12px', marginLeft: '2px' }} />
              {error?.text}
            </div>
          ))}
        </div>

        <div className="text-grey text-xs mb-4">*Please check the data in these fields and try again</div>
        <div className="flex flex-row w-full justify-between px-4 mb-4">
          <PrimaryButton onClick={() => handleRetry && handleRetry()}>Try Again</PrimaryButton>
          <SecondaryButton onClick={() => setShowModal(false)}>Cancel</SecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default InvalidCSVModal;
