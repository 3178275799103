import Table from '../../Dashboard/Tables/BaseTable';
import { propertySVGs } from '../../../../utils/helpers';
import * as numeral from 'numeral';
import 'numeral/locales/en-gb';

numeral.locale('en-gb');
numeral.defaultFormat('$0,0');

const EpcDataTable = ({ tableData, pageCount, pageIndex, fetchData, loading }) => {
  const columnsMetadata = {
    post_town: {
      Header: 'Location',
      accessor: 'post_town',
      headerStyle: {
        minWidth: '170px',
      },
      style: {
        color: '#000000',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '113px',
        paddingRight: '10px',
      },
    },
    district_code: {
      Header: 'District code',
      accessor: 'district_code',
      headerStyle: { width: '130px' },
      style: {
        width: '130px',
      },
    },
    property_type: {
      Header: 'Property Type',
      id: 'property_type',
      accessor: (d) => {
        return d?.property_type ? propertySVGs[d?.property_type]?.name : '';
      },
      headerStyle: { width: '94px' },
      style: {
        width: '90px',
      },
    },
    number_of_bedrooms: {
      Header: 'Number Of Bedrooms',
      accessor: 'number_of_bedrooms',
      headerStyle: { width: '106px' },
      style: {
        width: '90px',
      },
    },
    epc_rating: {
      Header: 'EPC Rating',
      id: 'epc_rating',
      accessor: (d) => {
        return d?.epc_rating ? d?.epc_rating : '-';
      },
      headerStyle: { width: '94px' },
      style: {
        width: '90px',
      },
    },
    price: {
      Header: 'Current Value (£)',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'price',
      formatter: (x) => (x ? numeral(x).format() : '-'),
    },
    curr_rating_to_c: {
      Header: 'Value increase to C (%)',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'curr_rating_to_c',
      formatter: (x) => (x && x !== 100 ? numeral(x).format('0.00%') : '-'),
    },
    increase_to_c: {
      Header: 'Value increase to C (£)',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      id: 'increase_to_c',
      accessor: (d) => {
        return d.increase_to_c && d.increase_to_c !== -100 && d.increase_to_c - d.price > 0
          ? numeral(d.increase_to_c - d.price).format()
          : '-';
      },
    },
    curr_rating_to_b: {
      Header: 'Value increase to B (%)',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'curr_rating_to_b',
      formatter: (x) => (x && x !== 100 ? numeral(x).format('0.00%') : '-'),
    },
    increase_to_b: {
      Header: 'Value increase to B (£)',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      id: 'increase_to_b',
      accessor: (d) => {
        return d.increase_to_b && d.increase_to_b !== -100 && d.increase_to_b - d.price > 0
          ? numeral(d.increase_to_b - d.price).format()
          : '-';
      },
    },
    curr_rating_to_a: {
      Header: 'Value increase to A (%)',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      accessor: 'curr_rating_to_a',
      formatter: (x) => (x && x !== 100 ? numeral(x).format('0.00%') : '-'),
    },
    increase_to_a: {
      Header: 'Value increase to A (£)',
      headerStyle: { width: '140px' },
      style: { color: '#000' },
      filterable: false,
      id: 'increase_to_a',
      accessor: (d) => {
        return d.increase_to_a && d.increase_to_a !== -100 && d.increase_to_a - d.price > 0
          ? numeral(d.increase_to_a - d.price).format()
          : '-';
      },
    },
  };

  return (
    <>
      <Table
        columnsMetadata={columnsMetadata}
        sampleTableData={tableData}
        filtersActive={false}
        paginationActive={true}
        staticData={false}
        title=""
        pageCount={pageCount}
        pageIndex={pageIndex}
        fetchData={fetchData}
        loading={loading}
      />
    </>
  );
};

export default EpcDataTable;
