import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './index.css';
import './chart.css';
import './step.css';
import Nav from './layouts/nav';
import Footer from './layouts/Footer';
import Home from './components/Dashboard/Home';
import { ReactComponent as LoaderSvg } from 'icons/custom/loader.svg';

import PortfolioAnalytics from './components/Portfolio/PortfolioAnalytics';
import Insights from './components/Insights/Insights';
import Alerts from './components/Alerts/Alerts';
import LocationSearch from './components/LocationSearch/LocationSearch';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Error404 from './pages/Error404';
import { ThemeContext } from '../context/ThemeContext';
import Portfolios from './components/Portfolios/Portfolios';
import Properties from './components/Properties/Properties';
import SetupPassword from './pages/SetupPassword';
import NewProperty from './components/Property/NewProperty';
import ScrollToTop from './components/Dashboard/Components/ScrollToTop';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import queryClient from '../utils/queryClient';
import Appraisal from './components/Appraisal/Appraisal';

import PropertyComparables from './components/PropertyComparables/PropertyComparables';
import Property from './components/Property/Property';
import Lha from './components/Lha/Lha';
import CompanySearch from './components/CompanySearch/CompanySearch';
import { getUser, isAuthenticated } from '../store/selectors/AuthSelectors';
import { connect } from 'react-redux';
import AppraisalPortfolios from './components/AppraisalPortfolios/AppraisalPortfolios';
import AppraisalProperties from './components/AppraisalProperties/AppraisalProperties';

const Markup = ({ user, isAuthenticated }) => {
  const { menuToggle } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(true);

  const [routes, setRoutes] = useState([
    { url: '', component: Home },
    { url: 'dashboard', component: Home },
    { url: 'property-comparables', component: PropertyComparables, param: 'propertyId' },
    { url: 'property-analytics', component: Property, param: 'propertyId' },
    { url: 'portfolio-analytics', component: PortfolioAnalytics, param: 'portfolioId' },
    { url: 'properties', component: Properties },
    { url: 'portfolios', component: Portfolios },
    { url: 'alerts', component: Alerts },
    { url: 'insights', component: Insights },
    { url: 'new-property', component: NewProperty },
    { url: 'location-search', component: LocationSearch },
    { url: 'page-login', component: Login },
    { url: 'page-forgot-password', component: ForgotPassword },
    { url: 'reset', component: ResetPassword },
    { url: 'welcome', component: SetupPassword },
    { url: 'page-error-404', component: Error404 },
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      const companySearchAccessData = process.env.REACT_APP_COMPANY_SEARCH_ACCESS;
      let companySearchAccess;

      try {
        companySearchAccess = companySearchAccessData ? JSON.parse(companySearchAccessData) : null;
      } catch (error) {
        console.log(error);
      }

      if (
        companySearchAccess?.company_id?.includes(user.company_id) ||
        companySearchAccess?.user_id?.includes(user.id)
      ) {
        setRoutes((prevRoutes) => [...prevRoutes, { url: 'company-search', component: CompanySearch }]);
      }

      const lhaAccessData = process.env.REACT_APP_LHA_ACCESS;
      let lhaAccess;

      try {
        lhaAccess = lhaAccessData ? JSON.parse(lhaAccessData) : null;
      } catch (error) {
        console.log(error);
      }

      if (lhaAccess?.company_id?.includes(user.company_id) || lhaAccess?.user_id?.includes(user.id)) {
        setRoutes((prevRoutes) => [...prevRoutes, { url: 'lha', component: Lha }]);
      }

      const appraisalAccessData = process.env.REACT_APP_APPRAISAL_ACCESS;
      let appraisalAccess;

      try {
        appraisalAccess = appraisalAccessData ? JSON.parse(appraisalAccessData) : null;
      } catch (error) {
        console.log(error);
      }

      if (appraisalAccess?.company_id?.includes(user.company_id) || appraisalAccess?.user_id?.includes(user.id)) {
        setRoutes((prevRoutes) => [...prevRoutes, { url: 'appraisal', component: Appraisal }]);
        setRoutes((prevRoutes) => [...prevRoutes, { url: 'appraisal-properties', component: AppraisalProperties }]);
        setRoutes((prevRoutes) => [...prevRoutes, { url: 'appraisal-portfolios', component: AppraisalPortfolios }]);
      }

      setIsLoading(false);
    }
  }, [user, isAuthenticated]);

  const path = window.location.pathname.split('/').at(-1);
  const pagePath = path?.split('-').includes('page');
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ScrollToTop />
        <div
          id={`${!pagePath ? 'main-wrapper' : ''}`}
          className={`${!pagePath ? 'show' : 'mh100vh'}  ${menuToggle ? 'menu-toggle' : ''}`}
        >
          {!pagePath && <Nav />}

          <div className={`${!pagePath ? 'content-body' : ''}`}>
            <div className={`${!pagePath ? 'container-fluid' : ''}`} style={{ minHeight: window.screen.height - 60 }}>
              {isLoading ? (
                <div>
                  <LoaderSvg className="animate-spin h-16 w-16 mx-auto my-8 text-white" />
                </div>
              ) : (
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}${data.param ? `/:${data.param}` : ''}`}
                      component={data.component}
                    />
                  ))}
                  <Route path="*">
                    <Redirect to={'/dashboard'} />
                  </Route>
                </Switch>
              )}
            </div>
          </div>
          {!pagePath && <Footer />}
        </div>
        {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </>
  );
};

// export default Markup;

export default connect((state) => ({
  isAuthenticated: isAuthenticated(state),
  user: getUser(state),
}))(Markup);
